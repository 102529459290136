var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Dialog',{attrs:{"dense":"","dialog":_vm.dialog,"dialog-width":_vm.dialogWidth},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.getTitle()))]},proxy:true},{key:"body",fn:function(){return [_c('div',{staticClass:"mx-4"},[_c('v-form',{ref:"groupForm",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.updateOrCreate.apply(null, arguments)}},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"formValid"}},[_c('v-row',{staticClass:"px-4"},[_c('v-col',{staticClass:"my-auto py-0",attrs:{"md":"4"}},[_c('label',{staticClass:"btx-label mt-2 required",attrs:{"for":"name"}},[_vm._v("Name")])]),_c('v-col',{staticClass:"py-0",attrs:{"md":"8"}},[_c('TextInput',{class:{
								required: !_vm.group.name,
							},attrs:{"hide-details":"","disabled":_vm.pageLoading,"loading":_vm.pageLoading,"id":"name","placeholder":"Name","rules":[_vm.vrules.required(_vm.group.name, 'Name')]},model:{value:(_vm.group.name),callback:function ($$v) {_vm.$set(_vm.group, "name", $$v)},expression:"group.name"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"md":"4"}},[_c('label',{staticClass:"btx-label mt-2",attrs:{"for":"description"}},[_vm._v("Description")])]),_c('v-col',{staticClass:"py-0",attrs:{"md":"8"}},[_c('TextAreaInput',{attrs:{"hide-details":"","disabled":_vm.pageLoading,"loading":_vm.pageLoading,"id":"description","placeholder":"Description"},model:{value:(_vm.group.description),callback:function ($$v) {_vm.$set(_vm.group, "description", $$v)},expression:"group.description"}})],1)],1),(false)?[_c('v-row',[_c('v-col',{staticClass:"my-auto py-0",attrs:{"md":"12"}},[_c('p',{staticClass:"middle-header-background my-4 px-4"},[_vm._v(" Depreciation"),_c('br'),_c('span',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-progress-question")]),_vm._v(" Enter the percentage to be depreciated each year.")],1)]),_c('div',{staticClass:"px-4"},[_c('label',{staticClass:"btx-label mt-2",attrs:{"for":"calculate-depreciation-of-assets-by-entering"}},[_vm._v("Calculate depreciation of Assets by entering ")]),_c('RadioInput',{attrs:{"disabled":_vm.pageLoading,"id":"calculate-depreciation-of-assets-by-entering","items":_vm.radioItems},model:{value:(_vm.group.depreciation),callback:function ($$v) {_vm.$set(_vm.group, "depreciation", $$v)},expression:"group.depreciation"}})],1)])],1),_c('v-row',{staticClass:"px-4"},[(_vm.group.depreciation == 1)?[_c('v-col',{staticClass:"my-auto py-0",attrs:{"md":"4"}},[_c('label',{staticClass:"btx-label mt-2 required",attrs:{"for":"depreciation-rate"}},[_vm._v("Depreciation rate(%) ")])]),_c('v-col',{staticClass:"py-0",attrs:{"md":"8"}},[_c('QuantityInput',{class:{
										required: !_vm.group.depreciation_rate,
									},attrs:{"hide-details":"","disabled":_vm.pageLoading,"loading":_vm.pageLoading,"id":"depreciation-rate","placeholder":"Depreciation rate(%)","rules":[_vm.vrules.required(_vm.group.depreciation_rate, 'Depreciation rate(%)')]},model:{value:(_vm.group.depreciation_rate),callback:function ($$v) {_vm.$set(_vm.group, "depreciation_rate", $$v)},expression:"group.depreciation_rate"}})],1)]:_vm._e(),(_vm.group.depreciation == 2)?[_c('v-col',{staticClass:"my-auto py-0",attrs:{"md":"4"}},[_c('label',{staticClass:"btx-label mt-2 required",attrs:{"for":"useful-life-in-months"}},[_vm._v("Useful Life in Months ")])]),_c('v-col',{staticClass:"py-0",attrs:{"md":"8"}},[_c('QuantityInput',{class:{
										required: !_vm.group.useful_life,
									},attrs:{"hide-details":"","disabled":_vm.pageLoading,"loading":_vm.pageLoading,"id":"useful-life-in-months","placeholder":"Useful Life in Months","rules":[_vm.vrules.required(_vm.group.useful_life, 'Useful Life in Months')]},model:{value:(_vm.group.useful_life),callback:function ($$v) {_vm.$set(_vm.group, "useful_life", $$v)},expression:"group.useful_life"}})],1)]:_vm._e(),_c('v-col',{staticClass:"min-height-40",attrs:{"md":"12"}})],2)]:_vm._e()],2)],1)]},proxy:true},{key:"action",fn:function(){return [_c('v-btn',{staticClass:"white--text",attrs:{"depressed":"","color":"blue darken-4","tile":"","disabled":_vm.pageLoading || !_vm.formValid,"loading":_vm.pageLoading},on:{"click":function($event){return _vm.updateOrCreate()}}},[_vm._v(" Save ")]),_c('v-btn',{attrs:{"depressed":"","tile":"","disabled":_vm.pageLoading,"loading":_vm.pageLoading},on:{"click":function($event){return _vm.$emit('close', true)}}},[_vm._v(" Close ")])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }