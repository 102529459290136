<template>
	<Dialog dense :dialog="dialog" :dialog-width="dialogWidth">
		<template v-slot:title>{{ getTitle() }}</template>
		<template v-slot:body>
			<div class="mx-4">
				<v-form
					ref="groupForm"
					v-model.trim="formValid"
					lazy-validation
					v-on:submit.stop.prevent="updateOrCreate"
				>
					<v-row class="px-4">
						<v-col md="4" class="my-auto py-0">
							<label for="name" class="btx-label mt-2 required">Name</label>
						</v-col>
						<v-col md="8" class="py-0">
							<TextInput
								hide-details
								:disabled="pageLoading"
								:loading="pageLoading"
								id="name"
								placeholder="Name"
								v-model="group.name"
								:rules="[vrules.required(group.name, 'Name')]"
								:class="{
									required: !group.name,
								}"
							></TextInput>
						</v-col>
						<v-col md="4" class="py-0">
							<label for="description" class="btx-label mt-2">Description</label>
						</v-col>
						<v-col md="8" class="py-0">
							<TextAreaInput
								hide-details
								:disabled="pageLoading"
								:loading="pageLoading"
								id="description"
								placeholder="Description"
								v-model="group.description"
							></TextAreaInput>
						</v-col>
					</v-row>
					<template v-if="false">
						<v-row>
							<v-col md="12" class="my-auto py-0">
								<p class="middle-header-background my-4 px-4">
									Depreciation<br />
									<span>
										<v-icon small>mdi-progress-question</v-icon> Enter the percentage to be depreciated each
										year.</span
									>
								</p>
								<div class="px-4">
									<label for="calculate-depreciation-of-assets-by-entering" class="btx-label mt-2"
										>Calculate depreciation of Assets by entering
									</label>
									<RadioInput
										v-model="group.depreciation"
										:disabled="pageLoading"
										id="calculate-depreciation-of-assets-by-entering"
										:items="radioItems"
									></RadioInput>
								</div>
							</v-col>
						</v-row>
						<v-row class="px-4">
							<template v-if="group.depreciation == 1">
								<v-col md="4" class="my-auto py-0">
									<label for="depreciation-rate" class="btx-label mt-2 required"
										>Depreciation rate(%)
									</label>
								</v-col>
								<v-col md="8" class="py-0">
									<QuantityInput
										hide-details
										:disabled="pageLoading"
										:loading="pageLoading"
										id="depreciation-rate"
										placeholder="Depreciation rate(%)"
										v-model="group.depreciation_rate"
										:rules="[vrules.required(group.depreciation_rate, 'Depreciation rate(%)')]"
										:class="{
											required: !group.depreciation_rate,
										}"
									></QuantityInput>
								</v-col>
							</template>
							<template v-if="group.depreciation == 2">
								<v-col md="4" class="my-auto py-0">
									<label for="useful-life-in-months" class="btx-label mt-2 required"
										>Useful Life in Months
									</label>
								</v-col>
								<v-col md="8" class="py-0">
									<QuantityInput
										hide-details
										:disabled="pageLoading"
										:loading="pageLoading"
										id="useful-life-in-months"
										placeholder="Useful Life in Months"
										v-model="group.useful_life"
										:rules="[vrules.required(group.useful_life, 'Useful Life in Months')]"
										:class="{
											required: !group.useful_life,
										}"
									></QuantityInput>
								</v-col>
							</template>
							<v-col md="12" class="min-height-40"> </v-col>
						</v-row>
					</template>
				</v-form>
			</div>
		</template>
		<template v-slot:action>
			<v-btn
				class="white--text"
				depressed
				color="blue darken-4"
				tile
				:disabled="pageLoading || !formValid"
				:loading="pageLoading"
				v-on:click="updateOrCreate()"
			>
				Save
			</v-btn>
			<v-btn
				depressed
				tile
				:disabled="pageLoading"
				:loading="pageLoading"
				v-on:click="$emit('close', true)"
			>
				Close
			</v-btn>
		</template>
	</Dialog>
</template>

<script>
import { mapGetters } from "vuex";
import Dialog from "@/view/components/Dialog";
import TextInput from "@/view/components/TextInput";
import TextAreaInput from "@/view/components/TextAreaInput";
import QuantityInput from "@/view/components/QuantityInput";
import RadioInput from "@/view/components/RadioInput";
import { toSafeInteger } from "lodash";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import { CreateGroup, UpdateGroup, GetGroup } from "@/core/lib/group.lib";
import { SET_LOCAL_DB } from "@/core/services/store/config.module";

export default {
	name: "group-template",
	props: {
		dialog: {
			type: Boolean,
			default: false,
		},
		uuid: {
			type: String,
			default: null,
		},
	},
	watch: {
		dialog(param) {
			if (param) {
				this.init();
			}
		},
	},
	data() {
		return {
			barcode: null,
			group: {
				name: null,
				description: null,
				depreciation: 1,
				depreciation_rate: 20,
				useful_life: 12,
			},
			formValid: true,
			pageLoading: false,
			radioItems: [
				{
					label: "Percentage of Depreciation",
					value: 1,
				},
				{
					label: "Useful Life of Assets",
					value: 2,
				},
			],
		};
	},
	components: {
		Dialog,
		RadioInput,
		TextInput,
		TextAreaInput,
		QuantityInput,
	},
	methods: {
		init() {
			this.group = {
				name: null,
				description: null,
				depreciation: 1,
				depreciation_rate: 20,
				useful_life: 12,
			};
			if (this.uuid) {
				this.getGroup();
			}
		},
		getGroup() {
			this.pageLoading = true;
			GetGroup(this.uuid)
				.then((data) => {
					this.group.name = data.name;
					this.group.description = data.description;
					this.group.depreciation = data.depreciation;
					this.group.depreciation_rate = data.depreciation_rate;
					this.group.useful_life = data.useful_life;
				})
				.catch((error) => {
					this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		getTitle() {
			if (this.uuid) {
				return this.group.name;
			}
			return "Create new Category";
		},
		async updateOrCreate() {
			const _this = this;

			const formErrors = _this.validateForm(_this.$refs.groupForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.groupForm.validate()) {
				return false;
			}

			const formData = {
				name: _this.group.name,
				description: _this.group.description,
				depreciation: _this.group.depreciation,
				depreciation_rate: _this.group.depreciation_rate,
				useful_life: _this.group.useful_life,
			};

			try {
				_this.pageLoading = true;

				if (_this.uuid) {
					await UpdateGroup(_this.uuid, formData);

					_this.$emit("close", true);
					_this.$emit("success", true);

					_this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Group has been updated." },
					]);
					_this.$store.dispatch(SET_LOCAL_DB);
				} else {
					await CreateGroup(formData);

					_this.$emit("close", true);
					_this.$emit("success", true);

					_this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Group has been created." },
					]);
					_this.$store.dispatch(SET_LOCAL_DB);
				}
			} catch (error) {
				_this.logError(error);
			} finally {
				_this.pageLoading = false;
			}
		},
	},
	computed: {
		...mapGetters(["errors"]),
		dialogWidth() {
			return toSafeInteger((document.body.clientWidth / 100) * 50);
		},
	},
};
</script>
